    .btn-xs {
    padding : 3px .844rem !important;
    font-size : .8rem !important;
    line-height : 1.5 !important;
    border-radius: 0.42rem !important;
    }
    .form-group {
    margin-bottom: 1.2rem !important;
    }
    

legend {
    width: auto !important;
}

.dataTables_paginate{
    float:right !important;
}
.card.card-custom>.card-header {
    min-height: 55px !important;
}
td > a {
    text-decoration: underline;
}

.table th, .table td {
    vertical-align: middle !important;
}
.table-sm th, .table-sm td {
    padding: 0.4rem !important;
}
.checkbox > span {
    border: 1px solid #99a2a9 !important;
}
.bg-custom {
    background-color: #28a74512 !important;
}
.modal-90w {
    width: 90% !important;
    max-width: none !important;
}
.flex-1 {
    -webkit-box-flex: 1 !important; 
    -ms-flex: 1 !important;
    flex: 1 !important;
}
.react-datepicker-wrapper{
    width: 100% !important;
}
.table td > .btn-link{
   padding: 0 !important;
}
.text-break {
    word-break: break-word!important;
    overflow-wrap: break-word!important;
}
.form-label {
    margin-bottom: 0.1rem !important;
}

.detailTableCustom{
    border-left: 3px solid #3699FF !important;
    padding-left: 25px !important;
}
.modal {
    padding-right: 0 !important ;
}

.modal-95w {
    width: 95% !important;
    max-width: none !important;
}
.modal-97w {
    width: 95% !important;
    max-width: none !important;
}
.img-resident{
    width: 230px !important;
    height: 250px !important;
    max-width: 250px !important;
    max-height: 250px !important;
    min-width: 250px !important;
    min-height: 250px !important;
}
.custom-file-input {
    font-size: 0.925rem !important;
    height: calc(1.35em + 1.1rem + 2px) !important;
    padding: 0.55rem 0.75rem !important;
    
}
.custom-file-label {
    height: calc(1.35em + 1.1rem + 2px) !important;
    padding: 0.55rem 0.75rem !important;
}
.custom-file-label::after {
    color: #fff !important;
    background-color: #3699FF !important;
    height: calc(1.35em + 1.1rem + 2px) !important;
    padding: 0.55rem 0.75rem !important;
    font-size: 0.925rem !important;
}
.modal .modal-header .close span {
    display: block !important;
    font-size: 25px !important;
}
.modal .modal-header .close {
    color: #000000 !important;
    
}
.custom-icon-size > li > .btn i {
    font-size: 1rem !important;
}
.custom-icon-size > li.active {
    transition: all 0.15s ease;
    background-color: #F3F6F9 !important;
}
@media (min-width: 992px){
    .header-menu .menu-nav > .menu-item > .menu-link {
        padding: 0.5rem 0.5rem !important;  
    }
}


    .trendReportInfo.table th, .trendReportInfo.table td {
        vertical-align: middle;
        border-top: 0px !important;
    }
